import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./detail-artikel.css";
import Ads from "../../components/ads/Ads";
import InfoKamus from "../../components/info-kamu/InfoKamus";
import DaunJatuh from "../../components/daun-jatuh/DaunJatuh";
import GoToTop from "../../components/goToTop/goToTop";
import Loading from "../../components/loading/Loading";
import { FormatDate } from "../../helpers/formatDate";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import instance from "../../config/axios";

function DetailArtikel() {
  const { slug, category } = useParams();
  // STATE DETAIL ARTIKEL
  const [detailArtikel, setDetailArtikel] = useState(null);
  const [loading, setLoading] = useState(false);

  // STATE DETAIL ARTIKEL
  const [artikelTerkait, setArtikelTerkait] = useState(null);
  const [loadingTerkait, setLoadingTerkait] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchDetailArtikel = async () => {
      const res = await instance.get(
        `api/artikel/${category.toLowerCase()}/${slug}`
      );
      try {
        setLoading(false);
        setDetailArtikel(res.data);
      } catch (error) {
        console.log("error :>> ", error);
        setLoading(false);
      }
    };
    fetchDetailArtikel();

    return () => {
      setDetailArtikel(null);
    };
  }, [slug, category]);

  // ARTIKEL TERKAIT
  useEffect(() => {
    setLoadingTerkait(true);
    const fetchArtikelTerakit = async () => {
      const res = await instance.get(`api/relatedartikel/${slug}`);
      try {
        setLoadingTerkait(false);
        setArtikelTerkait(res.data);
      } catch (error) {
        console.log("error :>> ", error);
        setLoadingTerkait(false);
      }
    };

    fetchArtikelTerakit();

    return () => {
      setArtikelTerkait(null);
    };
  }, [slug]);

  // SET INDEX SPLIT ARTIKEL
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(0);

  const handleIndex = (index) => {
    setIndex(index);
    setActive(index);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <script type="application/ld+json">{detailArtikel?.json_ld}</script>
      <div className="detail-artikel">
        <Helmet>
          <title>{`${detailArtikel?.judul_artikel}`} </title>
          <meta name="description" content={`${detailArtikel?.excerpt}`} />
        </Helmet>

        <DaunJatuh />
        <div className="detail-artikel-container">
          {loading && <Loading loading={loading} />}
          {detailArtikel && (
            <>
              <div className="detail-artikel-bradcrumb">
                Media / {detailArtikel.type_artikel}
              </div>
              <div className="detail-artikel-title">
                <h3>{detailArtikel.judul_artikel}</h3>
              </div>
              {/* <div className="jumlah-dibaca">
              Telah dibaca {detailArtikel.dibaca} kali
            </div> */}
              <div className="waktu-artikel">
                {FormatDate(detailArtikel.tanggal_publish)}
              </div>
              <div className="detail-artikel-gambar">
                <img
                  src={detailArtikel.gambar_artikel}
                  alt={detailArtikel.judul_artikel}
                />
              </div>
              <div className="detail-artikel-deskripsi">
                <p>{detailArtikel.excerpt}</p>
                <div className="ads-wrapper">
                  <Ads produk={detailArtikel.produk_rekomendasi} />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailArtikel.desc_artikel[index].desc,
                  }}
                ></div>
              </div>
            </>
          )}
          {/* Split Halaman */}
          <div className="split__pages">
            <div className="split__title">Halaman</div>
            {detailArtikel?.desc_artikel.length > 0 && (
              <div className="split__numbers">
                {detailArtikel?.desc_artikel.map((item, idx) => (
                  <div
                    className={`wrapper__nomor ${
                      active === idx ? "active" : ""
                    }`}
                    key={idx}
                    onClick={() => handleIndex(idx)}
                  >
                    {idx + 1}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Share Button */}
          <div className="share__buttons">
            <div className="share__title">Bagikan</div>
            <div className="share__icons">
              <div className="share__icon">
                <FacebookShareButton
                  url={`https://enesis.tmsdev.space/artikel/${slug}`}
                  title={`${detailArtikel?.judul_artikel}`}
                  quote={`${detailArtikel?.judul_artikel}`}
                  hashtag="#ademsari"
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
              </div>
              <div className="share__icon">
                <TwitterShareButton
                  url={`https://enesis.tmsdev.space/artikel/${slug}`}
                  quote={`${detailArtikel?.judul_artikel}`}
                  title={`${detailArtikel?.judul_artikel}`}
                  hashtag="#ademsari"
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
              </div>
              <div className="share__icon">
                <WhatsappShareButton
                  url={`https://enesis.tmsdev.space/artikel/${slug}`}
                  title={`${detailArtikel?.judul_artikel}`}
                  quote={`${detailArtikel?.judul_artikel}`}
                  hashtag="#ademsari"
                >
                  <WhatsappIcon size={32} />
                </WhatsappShareButton>
              </div>
            </div>
          </div>

          {/* Artikel Terkait */}
          <div className="artikel-terkait">
            {artikelTerkait ? (
              <InfoKamus
                title="Artikel Terkait"
                allArtikel={artikelTerkait.artikel_terkait}
                loading={loadingTerkait}
              />
            ) : (
              <div className="error-wrapper">
                Ada Kesalahan Teknis Related Artikel
              </div>
            )}
          </div>
        </div>
        <GoToTop />
      </div>
    </>
  );
}

export default DetailArtikel;
