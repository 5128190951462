import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import GoToTop from "../../components/goToTop/goToTop";
import InfoKamu from "../../components/info-kamu/InfoKamu";
import Intro from "../../components/intro/Intro";
import KontakFooter from "../../components/kontak-footer/KontakFooter";
import ProdukKami from "../../components/produk-kami/ProdukKami";
import PromoMenarik from "../../components/promo-menarik/PromoMenarik";
import { AuthContext } from "../../context/Context";
import { dataScriptBeranda } from "../../data/DataScript";
import "./beranda.css";

function Beranda() {
  const { isLogin } = useContext(AuthContext);
  return (
    <div className="beranda">
      <GoToTop />
      <Helmet>
        <title>Adem Sari | Adem Sari Cepat Ademnya</title>
        <meta name="description" content="Adem Sari Cepat Ademnya" />
        {dataScriptBeranda.map((schema, i) => (
          <script key={i} type="application/ld+json">
            {JSON.stringify(schema, null, 2)}
          </script>
        ))}
      </Helmet>

      <Intro />
      <PromoMenarik />
      <ProdukKami />
      <InfoKamu />
      {!isLogin ? <KontakFooter /> : <div style={{ height: "20vh" }}></div>}
    </div>
  );
}

export default Beranda;
