export const dataScriptBeranda = [
  {
    "@context": {
        "@vocab": "http://schema.org/"
    },
    "@type": "Organization",
    "name": "Adem Sari",
    "image": "https://www.ademsari.co.id/assets/images/logo-utama.png",
    "address": {
        "@type": "PostalAddress",
        "email": "customer.service@enesis.com",
        "streetAddress": "PT. Sari Enesis Indah (Adem Sari) Jl. Rawa Sumur III, Blok DD No. 13 Pulo Gadung Industrial Estate Jakarta 13930",
        "telephone": "(+62) 8001 282828"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "areaServed": "ID",
        "availableLanguage": "Indonesian",
        "contactOption": "TollFree",
        "contactType": "customer service",
        "telephone": "(+62) 8001 282828"
    },
    "description": "Grup Enesis (Adem Sari)  bertekad untuk menciptakan produk-produk baru dan memperluas pasar baik secara lokal maupun global. Ekspansi teranyar Enesis meliputi proyek penetrasi pasar di China, Vietnam, Thailand, dan Amerika Serikat.",
    "sameAs": [
      "https://www.instagram.com/ademsari_id/",
      "https://www.youtube.com/user/AdemSariID",
      "https://www.ademsari.co.id/"
    ]
  },
  {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "Adem Sari",
    "alternateName": "PT Sari Enesis Indah",
    "url": "https://www.ademsari.co.id/",
    "logo": "https://www.ademsari.co.id/assets/images/logo-utama.png",
    "sameAs": [
      "https://www.instagram.com/ademsari_id/",
      "https://www.youtube.com/user/AdemSariID",
      "https://www.ademsari.co.id/"
    ]
  }
] 